import {
	Autocomplete,
	Chip,
	CircularProgress,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"

const SingleBlockFilter = ({
	fieldName,
	path,
	formik,
	pathChangeable = false
}) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [selectedBlock, setSelectedBlock] = useState("")
	const [searchParams, setSearchParams] = useSearchParams()
	const [value, setValue] = useState(null);
	const { data, isLoading, isFetching, refetch } = useQuery({
		queryKey: "reasonFilterSelect",
		queryFn: async function () {
			const response = await axiosPrivate.get(path)
			return response.data.data
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	const handleChange = (event,index) => {
		const value = +event.target.dataset.optionIndex + 1
		searchParams.set(`reason_id`, value)
		let localSearchParams = Object.fromEntries([...searchParams])
		if (!isNaN(localSearchParams.page)) {
			searchParams.set("page", 1)
		}
		setSearchParams(searchParams)
		setSelectedBlock(value)
	}

	const handleClearSelectBox = () => {
		searchParams.delete("reason_id")
		setSelectedBlock("")
		setValue(null);
		setSearchParams(searchParams)
	}
	useEffect(() => {
		if (pathChangeable) {
			refetch()
			formik.setFieldValue(fieldName, "", true)
			searchParams.delete("reason_id")
			setSelectedBlock("")
			setSearchParams(searchParams)
		}
	}, [path])
	return (
		// <FormControl fullWidth color="formColor">
		// 	<InputLabel id="single-block-filter-label">
		// 		{t("expense.modal.fields.typeExpenses")}
		// 	</InputLabel>
		// 	<Select
		// 		labelId="single-block-filter-label"
		// 		id="single-block-filter-chip"
		// 		label={t("expense.modal.fields.typeExpenses")}
		// 		value={selectedBlock}
		// 		onChange={handleChange}
		// 		color="formColor"
		// 		variant="outlined"
		// 		MenuProps={{
		// 			id: "single-block-filter-select-menu",
		// 			PaperProps: {
		// 				style: {
		// 					maxHeight: 300
		// 				}
		// 			},
		// 			disableScrollLock: true
		// 		}}
		// 		endAdornment={
		// 			<InputAdornment position="end" className="custom-endAdornment">
		// 				{selectedBlock && (
		// 					<IconButton
		// 						onClick={() =>{ 
		// 							handleClearSelectBox()
									
		// 						}}
		// 						variant="onlyIcon"
		// 						className="select-box-end-adornment-button"
		// 					>
		// 						<i className="bi bi-x end-adornment-close-button" />
		// 					</IconButton>
		// 				)}
		// 			</InputAdornment>
		// 		}
				
		// 	>
		// 		{isLoading || isFetching ? (
		// 			<div className="circular-progress-box">
		// 				<CircularProgress size={25} />
		// 			</div>
		// 		) : data && data.length > 0 ? (
		// 			data.map((item, index) => (
		// 				<MenuItem value={item.id} key={`block-${index + 1}`}>
		// 					{item.reason}
		// 				</MenuItem>
		// 			))
		// 		) : (
		// 			<div>
		// 				<span className="no-data-found-wrapper select-box">
		// 					<i className="bi bi-exclamation-octagon icon-lg" />{" "}
		// 					{t("common.global.noDataFound")}
		// 				</span>
		// 			</div>
		// 		)}
		// 	</Select>
		// </FormControl>
		<Fragment>
			<Autocomplete
				id={`autocomplete`}
				options={data || []}
				fullWidth
				value={value}
				onChange={(event, newValue) => {
					setValue(newValue);
					handleChange(event)
				  }}
				loading={isLoading || isFetching}
				inputValue={selectedBlock}
				noOptionsText={t("common.global.noDataFound")}
				getOptionLabel={(option) => (option.reason ? option.reason : "")}
				onInputChange={(event, newInputValue) => {
					setSelectedBlock(newInputValue);
				  }}
				  disableClearable
				loadingText={
					<div className="circular-progress-box">
						<CircularProgress size={25} />
					</div>
				}
				readOnly={false}
				disabled={false}
				renderInput={(params) => (
					<TextField
						{...params}
						color="formColor"
						variant="outlined"
						label={t("expense.modal.fields.typeExpenses")}
						value={"Test"}
						autoComplete="off"
						InputProps={{
							...params.InputProps,
							endAdornment: (
							  <InputAdornment position="end">
								{selectedBlock && (
								<IconButton
								  onClick={handleClearSelectBox}
								  aria-label="clear input"
								  className="select-box-end-adornment-button"
								>
								  <i className="bi bi-x end-adornment-close-button" />
								</IconButton>
								)}
								{params.InputProps.endAdornment}
							  </InputAdornment>
							),
						  }}
					/>
				)}
			/>
		</Fragment>
	)
}

export default SingleBlockFilter
